/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import { findElement } from '../helpers';

// Connects to data-controller="ai-assistant"
export default class extends Controller {
  static targets = ['toggleStatus', 'assistant', 'sortableAssistants', 'projectSelect'];
  connect() {
    if (this.hasSortableAssistantsTarget) {
      this.setSortable();
    }
  }

  toggleStatus(event) {
    const checked = event.target.checked;

    let existingRoles = [];
    let assistantId = '';

    if (this.hasToggleStatusTarget) {
      existingRoles = this.toggleStatusTarget?.dataset?.roles || [];
      assistantId = this.toggleStatusTarget?.dataset?.assistantId || '';
    }

    post('/admin/ai/toggle_assistant_status', {
      body: {
        disable_role_setting: checked,
        existing_roles: existingRoles,
        assistant_id: assistantId
      },
      responseKind: 'turbo-stream'
    });
  }

  onSelectedProject() {
    if (this.projectSelectTarget.value) {
      const selectedProject = this.projectSelectTarget.value;
      post('/admin/ai/update_selected_project_assistants', {
        query: {
          project_type: selectedProject,
          assistant_id: this.projectSelectTarget?.dataset?.assistantId || ''
        },
        responseKind: 'turbo-stream'
      });
    }
  }

  updateActiveList(event) {
    event.preventDefault();

    let assistantId = '';

    if (this.hasAssistantTarget) {
      assistantId = this.assistantTarget?.dataset?.assistantId || '';
    }

    post('/ai/assistants/update_in_use_assistant', {
      body: {
        assistant_id: assistantId
      },
      responseKind: 'turbo-stream'
    });
  }

  setSortable() {
    let sortableUl = findElement(this.element, '#assistant-sortable-list');
    let sortable = HSCore.components.HSSortable.collection;
    let existing = sortable.find((elm) => elm.$el === sortableUl);
    if (!existing) {
      HSCore.components.HSSortable.init(sortableUl);
      existing = HSCore.components.HSSortable.collection.find((elm) => elm.$el === sortableUl);
    }

    this.sortable = existing.$initializedEl;
  }

  resort(event) {
    event.stopImmediatePropagation();
    let sortableList = findElement('.js-sortable#assistant-sortable-list');
    let children = [...sortableList.children];

    const inUseAssistantIds = children.map((element) =>
      Number(element.getAttribute('data-in-use-assistant-id'))
    );

    post('/ai/assistants/resort_active_assistants', {
      body: {
        in_use_assistant_ids: inUseAssistantIds
      },
      responseKind: 'turbo-stream'
    });
  }
}
